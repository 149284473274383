<template>
  <div id="app" class="background-animation login-container">
    <div v-if="!isLoggedIn" class="login-form">
      <form @submit.prevent="authenticate">
        <input v-model="username" placeholder="Username" class="input-field" />
        <input v-model="password" type="password" placeholder="Password" class="input-field" />
        <button type="submit" class="button-primary">Login</button>
      </form>
      <p v-if="message" class="login-message">{{ message }}</p>
    </div>
    <div v-else>
      <main-lobby></main-lobby>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// Import your main lobby component
import MainLobby from './MainLobby.vue';

export default {
  components: {
    MainLobby
  },
  data() {
    return {
      username: '',
      password: '',
      message: '',
      isLoggedIn: false
    }
  },
  methods: {
    authenticate() {
      axios.post('https://dashamajig.chibitronics.com/api/authenticate', {
        username: this.username,
        password: this.password
      })
          .then(() => {
            this.isLoggedIn = true;
            this.message = 'Logged in successfully.';
          })
          .catch(error => {
            this.message = error.response.data.error;
          });
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.login-message {
  margin-top: 20px;
}

</style>