<template>
  <div class="main-lobby">
    <div class="content-wrapper">
      <h1 class="gradient-text">Welcome to Dashamajig!</h1>
      <h2 class="gradient-text">You are privy to the most exclusive of insights, man.</h2>
      <button @click="fetchOrders" class="fetch-button">{{ loadButtonText }}</button>
      <div v-if="orders.length > 0">
        {{ orders.length }} orders loaded
        <!-- Search bar -->
        <input type="text" v-model="searchQuery" placeholder="Search orders..." class="search-bar">
      </div>
      <div v-if="orders.length > 0" class="orders-table">
        <table class="table">
          <thead>
          <tr>
            <th @click="sortTable('order_number')" class="sortable">Order Number</th>
            <th @click="sortTable('date_added')" class="sortable">Date Placed</th>
            <th @click="sortTable('date_shipped')" class="sortable">Date Shipped</th>
            <th @click="sortTable('shipping_address')" class="sortable">Shipping Address</th>
            <th>Item Details</th> <!-- Not sortable due to complex content -->
            <th @click="sortTable('ship_charge_total')" class="sortable">Total Shipping Cost</th>
            <th @click="sortTable('retail_value')" class="sortable">Retail Value</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="order in filteredOrders" :key="order.id">
            <td>{{ order.order_number }}</td>
            <td>{{ order.date_added }}</td>
            <td>{{ order.date_shipped }}</td>
            <td>{{ order.shipping_address }}</td>
            <td>
              <ul>
                <li v-for="item in order.items" :key="item.sku">
                  {{ item.quantity }}x {{ item.description }} (SKU: {{ item.sku }}) - ${{ item.retail_value }}
                </li>
              </ul>
            </td>
            <td>${{ order.ship_charge_total }}</td>
            <td>${{ order.retail_value.toFixed(2) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style>
.sortable {
  cursor: pointer;
}
</style>

<script>
import FulfillriteService from '@/services/FulfillriteService';

export default {
  data() {
    return {
      orders: [],
      currentSortField: 'date_added',  // Set default sort field to 'date_added'
      currentSortDir: -1,  // Set default sort direction to -1 for descending order
      searchQuery: '',
      page: 1,
      pageSize: 100,  // Load 100 orders at a time
      hasMoreOrders: true,
      loadButtonText: 'Fetch Orders'
    };
  },
  computed: {
    filteredOrders() {
      if (!this.orders) return [];
      return this.orders.filter(order => {
        const directMatch = Object.entries(order).some(([key, value]) => {
          if (key === 'items') return false;
          if (value === null || value === undefined) return false;
          return value.toString().toLowerCase().includes(this.searchQuery.toLowerCase());
        });

        const itemMatch = order.items.some(item => {
          return Object.values(item).some(innerValue => {
            if (innerValue === null || innerValue === undefined) return false;
            return innerValue.toString().toLowerCase().includes(this.searchQuery.toLowerCase());
          });
        });

        return directMatch || itemMatch;
      }).sort((a, b) => {
        let modifier = this.currentSortDir;
        if (!a[this.currentSortField] || !b[this.currentSortField]) return 0;
        if (a[this.currentSortField] < b[this.currentSortField]) return -1 * modifier;
        if (a[this.currentSortField] > b[this.currentSortField]) return 1 * modifier;
        return 0;
      });
    }
  },
  methods: {
    fetchOrders() {
      if (!this.hasMoreOrders) return;

      console.log('Fetching orders:', this.page, this.pageSize);

      FulfillriteService.getOrders(this.page, this.pageSize)
          .then(response => {
            const newOrders = response.data.map(order => ({
              ...order,
              date_added: order.date_added ? this.formatDate(order.date_added) : 'N/A',
              date_shipped: order.date_shipped ? this.formatDate(order.date_shipped) : 'N/A',
              retail_value: order.items.reduce((total, item) => total + (item.retail_value * item.quantity), 0)
            }));

            // Ensure new orders are not duplicates
            const uniqueOrders = newOrders.filter(newOrder => !this.orders.some(order => order.id === newOrder.id));

            if (uniqueOrders.length < this.pageSize) {
              this.hasMoreOrders = false;
              this.loadButtonText = 'No More Orders';
            } else {
              this.loadButtonText = 'Load More';
            }

            this.orders = [...this.orders, ...uniqueOrders];
            this.sortOrders();  // Ensure orders are sorted right after fetching
            this.page += 1;
            console.log("Orders set on component:", this.orders);
          })
          .catch(error => {
            console.error('There was an error:', error.response);
          });
    },
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'N/A';
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
    },
    sortTable(field) {
      if (this.currentSortField === field) {
        this.currentSortDir *= -1;
      } else {
        this.currentSortField = field;
        this.currentSortDir = 1;
      }
      this.sortOrders();  // Sort orders whenever the field or direction changes
    },
    sortOrders() {
      if (!this.orders) return;
      this.orders.sort((a, b) => {
        let modifier = this.currentSortDir;
        if (!a[this.currentSortField] || !b[this.currentSortField]) return 0;
        if (a[this.currentSortField] < b[this.currentSortField]) return -1 * modifier;
        if (a[this.currentSortField] > b[this.currentSortField]) return 1 * modifier;
        return 0;
      });
    }
  }
};
</script>
