import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://dashamajig.chibitronics.com/api/fulfillrite',
  withCredentials: false, // This depends on your configuration
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
});

export default {
  getOrders() {
    return apiClient.get('/orders');
  }
};
